import React from 'react';
import { css } from '@emotion/core';

const Calculate = ({ location, channelUrl }) => {
  console.log(location);
  return (
    <>
      <pre
        css={css`
          color: #fafafa;
        `}
      >
        channelUrl: {channelUrl}
      </pre>
    </>
  );
};

export default Calculate;
